<template>
	<div class="Reset">
		<div v-loading="loading"></div>
		<el-form :model="form" ref="Forms" :rules="rule" label-width="100px" class="resetForm flex">
			<div class="reset reset1">
				<input type="hidden">
				<el-form-item label="原密码" prop="oldPwd" >
					<el-input v-model="form.oldPwd" placeholder="请输入您的原密码"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPwd">
					<el-input v-model="form.newPwd" type="password" placeholder="请输入您的新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码" prop="confirmNewPwd">
					<el-input v-model="form.confirmNewPwd" type="password" placeholder="请输入您的确认新密码"></el-input>
				</el-form-item>
			</div>
		</el-form>
		<div class="tc">
			<el-button :loading='loading1' type="info" size="medium" @click="InfoSubmit('Forms')" class="confirmBtn">确认修改</el-button>
		</div>
	</div>
</template>

<script>
	import * as profile from "@/api/profile.js";
	import { dataState } from "@/api/all.js";
  import { GetWxShares } from "@/utils/common";

	export default {
		name: "Reset",
		data() {
			return {
				loading: true,       // !loading效果
				loading1: false,     // !修改信息按钮
				Isfocus: false,      // !获焦改变密码输入
				form: {
					oldPwd: '',        // !原密码
					newPwd: '',        // !新密码
					confirmNewPwd: '', // !确认新密码
				},
				submitForm: {
					oldPwd: '',        // !原密码
					newPwd: '',        // !新密码
				},
				rule: {
					oldPwd: [
						{ required: true,message: "请输入您的原密码",trigger: "blur" },
						{ min: 6, max: 16, message: "长度在6个字符至16个字符中间", trigger: "blur" },
					],
					newPwd: [
						{ required: true, message: "请输入您的新密码", trigger: "blur" },
						{ min: 6, max: 16, message: "长度在6个字符至16个字符中间", trigger: "blur" }
					],
					confirmNewPwd: [
						{ required: true, message: "请输入您的确认新密码", trigger: "blur"},
						{ min: 6, max: 16, message: "长度在6个字符至16个字符中间", trigger: "blur" }
					],
				},
			}
		},
		mounted() {
      const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
			this.loading = false;
			// 数据统计
			const data ={
				"visitType": 1,
				"gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
				"url": window.location.href,
				"pageUrl": "Reset",
				"relationId": ""
			}
			dataState(data).then(res => {
				sessionStorage.setItem('gid',res.data);
			})
		},
		methods: {
			// 确认修改
			InfoSubmit(forms) {
				this.$refs[forms].validate((valid) => {
					if (valid) {
						if(this.form.newPwd != this.form.confirmNewPwd){
							this.$toast("两次输入的新密码不一致！");
							return
						}
						this.loading = true;
						this.loading1 = true;
						this.submitForm.oldPwd =  this.form.oldPwd
						this.submitForm.newPwd =  this.form.newPwd
						profile.changePwd(this.submitForm).then(res => {
							
							if (res.status == 1) {
								this.$toast('密码修改成功，请重新登录账号！')
								setTimeout(() => {
									localStorage.removeItem("token");
									localStorage.removeItem("token2");
									this.$router.push('/Login')
								}, 1000);
							} else {
								this.$toast(res.message);
								this.loading = false;
								this.loading1 = false;
							}
						})
					}
				})
			},
		},
	
		watch: {
			form(val) {
			}
		}
	}
</script>

<style lang="scss">
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}
	#app .confirmBtn{width: 136px;}

	.Reset {
		padding: 140px 40px; width: 100%;
		.resetForm {width: 100%;justify-content: center;}
		.reset {width: 46%;}
	}

	@media (max-width: 750px) {
		.Reset {
			padding: vw(120) vw(30);
			.reset {width: 100%;}
		}
		.el-form-item,.el-form-item__label {height: auto;line-height: vw(60);}
	}
</style>
